import React from "react";
import styled, { Box } from "@xstyled/styled-components";

const SocialStyled = styled(Box)`
  margin-left: md;
  color: light.500;
  transition: medium;

  &:hover {
    color: light.900;
  }
`;

export function Social(props) {
  return <SocialStyled as="a" rel="noopener" target="_blank" {...props} />;
}
