export const theme = {
  colors: {
    light: {
      500: "#F7F8F8",
      700: "#95a2b3",
      900: "#D0D6E0",
    },
    dark: {
      500: "#27282B",
      700: "#1c1d1f",
      900: "#1F2023",
    },
    sub: {
      1: "#6AB486",
      2: "#EBC861",
      3: "#5E6CCA",
      4: "#DA615C",
      5: "#54ABC2",
    },
    link: {
      light: "#828fff",
      hover: "#ced2fa",
    },
  },
  fonts: {
    text: "'Nunito Sans'",
    title: "'Baloo 2'",
  },
  fontWeights: {
    regular: "400",
    bold: "600",
  },
  fontSize: {
    small: "10",
    regular: "18",
    big: "24",
  },
  radii: {
    lg: 15,
  },
  breakpoints: {
    xs: 0,
    sm: 480,
    md: 736,
    lg: 980,
    xl: 1280,
  },
  space: {
    xs: 5,
    sm: 10,
    md: 15,
    lg: 20,
    xl: 25,
    xxl: 30,
  },
  transitions: {
    medium: "300ms ease",
  },
};
