import React from "react";
import { Link as LinkGatsby } from "gatsby";
import styled, { css } from "@xstyled/styled-components";
import { variant } from "@xstyled/system";

const LinkStyled = styled.a`
  position: relative;
  display: inline-flex;

  text-decoration: none;
  transition: medium;

  &:hover {
    color: link.hover;
  }
  ${variant({
    default: "primary",
    variants: {
      primary: css`
        color: link.light;
      `,
      light: css`
        color: light.500;
      `,
    },
  })}
`;

export function Link({ children, href, to, variant, ...props }) {
  return (
    <LinkStyled
      variant={variant}
      as={to ? LinkGatsby : undefined}
      href={href}
      to={to}
      rel={props.target && props.target === "_blank" ? "noopener" : ""}
      {...props}
    >
      {children}
    </LinkStyled>
  );
}
