import React from "react";
import { Link } from "gatsby";

import styled, { Box, css } from "@xstyled/styled-components";

import { GithubIcon, LinkedinIcon, TwitterIcon } from "./Icons";
import { Social } from "./Social";
const iconSize = 27;
const iconProps = { width: iconSize, height: iconSize };

export const Logo = styled(Box)`
  color: light.500;
  text-decoration: none;
  font-family: title;
  font-size: 20;
  transition: medium;

  &:hover {
    color: light.900;
  }
`;

const shouldShow = (props) => {
  if (props.scrolled) {
    if (props.showOnScroll) return true;
    if (props.hideOnScroll) return false;
  }
  if (props.showOnScroll) return false;
  return true;
};

const Handle = styled.span`
  transition: opacity 250ms ease-out, margin 250ms ease-in-out;
  ${(props) => {
    if (shouldShow(props)) {
      return css`
        opacity: 1;
      `;
    }
    return css`
      opacity: 0;
      margin-right: ${props.marginOnHide || "-.5em"};
    `;
  }};
`;

export const Header = React.forwardRef(({ isScrolled }, ref) => {
  return (
    <Box
      alignItems="center"
      as="header"
      display="flex"
      justifyContent="space-between"
      pb="md"
      position="sticky"
      pt="lg"
    >
      <Logo ref={ref} as={Link} to="/">
        <Handle scrolled={isScrolled} showOnScroll>
          &lt;
        </Handle>
        Steven
        <Handle scrolled={isScrolled} hideOnScroll marginOnHide="-.2em">
          &nbsp;
        </Handle>
        <Handle scrolled={isScrolled} hideOnScroll marginOnHide="-3.3em">
          sanséau
        </Handle>
        <Handle scrolled={isScrolled} showOnScroll>
          /&gt;
        </Handle>
      </Logo>
      <Box display="flex">
        <Social href="https://github.com/steven-sanseau">
          <GithubIcon {...iconProps} />
        </Social>
        <Social href="https://twitter.com/stevensanseau">
          <TwitterIcon {...iconProps} />
        </Social>
        <Social href="https://www.linkedin.com/in/stevensanseau">
          <LinkedinIcon {...iconProps} />
        </Social>
      </Box>
    </Box>
  );
});
