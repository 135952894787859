import React from "react";
import { Normalize } from "styled-normalize";
import {
  createGlobalStyle,
  ThemeProvider,
  Box,
} from "@xstyled/styled-components";

import { theme } from "../theme";

import { Header } from "./Header";
import { Centered } from "./Centered";
import { Footer } from "./Footer";
import { Seo } from "./Seo";

const GlobalStyle = createGlobalStyle`
  ::selection {
    background-color: light.900;
    color: light.500;
  }

  body {
    background-color: dark.900;
    color: light.500;
    font-family: text;
    font-size: 18;
    line-height: 1.4;
  }
`;

export function Layout({ children, title }) {
  const [isScrolled, setScrolled] = React.useState(false);
  const ref = React.useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      const scrolled = winScroll / height;
      setScrolled(scrolled > 0.1);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Normalize />
        <GlobalStyle />
        <Box
          backgroundColor="rgba(45, 47, 53, 0.9)"
          boxShadow={isScrolled ? "rgba(0, 0, 0, 0.15) 0px 1px 4px 0px" : null}
          position="fixed"
          top="0"
          width="100%"
          zIndex="1"
        >
          <Centered>
            <Header ref={ref} isScrolled={isScrolled} />
          </Centered>
        </Box>
        <Centered>
          <Seo
            title={title}
            description="Freelance full stack engineer based in Paris. Specialized in React & React Native on front-end and NodeJs on back-end."
          />
          {children}
          <Footer />
        </Centered>
      </ThemeProvider>
    </>
  );
}
