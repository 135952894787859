import React from "react";
import styled, { Box } from "@xstyled/styled-components";

import profilePicture from "../images/profile.png";

import { TwitterIcon } from "./Icons";
import { Social } from "./Social";
import { Link } from "./Link";
import { CookieBanner } from "./CookieBanner";

const Face = styled.div`
  width: 30;
  height: 30;
  overflow: hidden;
  border-radius: 15;
  margin-left: sm;
  position: relative;
`;

const MyFace = styled.img`
  width: 40;
  height: 40;
  position: relative;
  transform: translate3d(-6px, -1px, 0);
  transition: medium;
  cursor: none;

  &:hover {
    transform: scale3d(3, 3, 3);
  }
`;

export function Footer() {
  return (
    <>
      <Box
        display="flex"
        fontSize={14}
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        my="xxl"
        pt="xxl"
      >
        <Social
          alignItems="center"
          display="inline-flex"
          href="https://twitter.com/stevensanseau"
          ml="0"
        >
          <TwitterIcon height={20} width={20} />
          <Box as="span" ml="xs">
            Follow me on Twitter
          </Box>
        </Social>
        <Box>
          <Link variant="light" to="/about">
            credits
          </Link>
        </Box>
        <Box alignItems="center" display="flex">
          Steven Sanséau
          <Face>
            <MyFace alt="My face" src={profilePicture} />
          </Face>
        </Box>
      </Box>
      <Box width="100%" textAlign="center">
        <CookieBanner />
      </Box>
    </>
  );
}
