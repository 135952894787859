import React from "react";
import styled, { Box } from "@xstyled/styled-components";

const CookieCrumbs = (props) => (
  <svg
    width={props.width || 483}
    height={props.height || 490}
    viewBox="0 0 483 490"
  >
    <g fill="#F7F8F8" fillRule="evenodd">
      <path
        d="M140.354 119.994l-3.102.04c-13.366 1.006-23.651 12.595-23.061 25.986.59 13.39 11.855 24.031 25.257 23.858 13.403-.174 24.388-11.103 24.631-24.505.243-13.401-10.338-24.72-23.725-25.38zM99.692 223.936l-5.322.068c-22.931 1.726-40.577 21.61-39.565 44.584 1.013 22.974 20.34 41.231 43.334 40.933 22.994-.298 41.842-19.049 42.259-42.042.417-22.992-17.737-42.412-40.706-43.543zM202.456 200.364l-4.211.054c-18.148 1.366-32.112 17.102-31.31 35.283.8 18.18 16.095 32.628 34.292 32.392 18.197-.235 33.112-15.074 33.442-33.27.33-18.195-14.037-33.563-32.213-34.459zM135.636 338.936l-3.235.041c-13.937 1.05-24.661 13.134-24.046 27.097.615 13.963 12.361 25.058 26.337 24.877 13.975-.18 25.43-11.577 25.683-25.551.253-13.974-10.78-25.777-24.74-26.464zM279.746 315.364l-5.498.07c-23.69 1.784-41.921 22.327-40.875 46.062 1.046 23.735 21.013 42.596 44.769 42.289 23.756-.308 43.228-19.68 43.659-43.435.43-23.754-18.325-43.817-42.055-44.986zM376.57 276.472c-13.388 1.008-23.69 12.624-23.099 26.045.591 13.421 11.875 24.086 25.3 23.912 13.425-.174 24.428-11.128 24.672-24.56.243-13.432-10.356-24.776-23.765-25.437l-3.107.04zM316.57 196.472c-13.388 1.008-23.69 12.624-23.099 26.045.591 13.421 11.875 24.086 25.3 23.912 13.425-.174 24.428-11.128 24.672-24.56.243-13.432-10.356-24.776-23.765-25.437l-3.107.04zM284.684 67.647l-5.344.069c-23.027 1.734-40.747 21.714-39.73 44.798 1.016 23.085 20.424 41.429 43.515 41.13 23.09-.3 42.017-19.141 42.436-42.244.418-23.103-17.812-42.616-40.877-43.753z"
        fillRule="nonzero"
      />
      <path
        d="M472.058 301.926c-30.505 121.402-151.032 197.4-273.726 172.597-53.837-10.883-95.662-43.452-132.72-78.62-44.937-42.644-63.16-117.316-52.47-186.748C23.58 141.351 64.204 68.858 115.487 49.34 164.6 30.645 213.317 5.788 269.572 11.775c52.733 5.613 152.187 66.643 176.963 137.566 10.977 31.423 19.485 82.285 25.523 152.585z"
        stroke="#F7F8F8"
        strokeWidth="20"
        strokeLinecap="round"
        fill="none"
      />
    </g>
  </svg>
);

const CookieCrumbs1 = (props) => (
  <svg
    width={props.width || 467}
    height={props.height || 474}
    viewBox="0 0 467 474"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#F7F8F8">
        <path
          d="M264.95 134.741c-21.378-11.897-35.348-34.07-36.937-58.623-1.589-24.553 9.43-47.997 29.133-61.984"
          stroke="#F7F8F8"
          strokeWidth="20.009"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M456.943 306.577c-25.412 17.308-59.59 16.482-84.942-2.052-25.352-18.534-36.229-50.645-27.032-79.807"
          stroke="#F7F8F8"
          strokeWidth="20.103"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M339.99 230.433c-24.997 2.595-49.976-8.308-65.265-28.486-15.29-20.179-18.49-46.466-8.362-68.684"
          stroke="#F7F8F8"
          strokeWidth="19.137"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M150.74 112.15c13.08.644 23.284 11.56 23.046 24.654-.237 13.094-10.83 23.632-23.925 23.802-13.095.17-23.958-10.09-24.535-23.174-.576-13.083 9.342-24.26 22.4-25.243M110.179 213.04c22.35 1.1 39.787 19.753 39.382 42.127-.406 22.374-18.507 40.382-40.884 40.672-22.376.29-40.937-17.243-41.923-39.599-.985-22.356 15.962-41.454 38.277-43.134M212.852 189.806c18.533.913 32.992 16.38 32.655 34.932-.336 18.553-15.346 33.486-33.9 33.726-18.555.24-33.946-14.298-34.763-32.836-.817-18.538 13.235-34.374 31.739-35.767M290.181 303.953c24.532 1.209 43.67 21.681 43.225 46.24-.445 24.557-20.313 44.322-44.873 44.64-24.56.319-44.933-18.925-46.014-43.463-1.082-24.538 17.52-45.5 42.012-47.344M148.719 329.625c13.08.645 23.284 11.56 23.047 24.654-.238 13.094-10.83 23.633-23.926 23.802-13.095.17-23.957-10.09-24.534-23.174-.577-13.083 9.341-24.26 22.4-25.243"
          fillRule="nonzero"
        />
      </g>
      <path
        d="M411.571 157.008C400.145 170.264 383 229.15 383 229.15l56.071 11.071c23.29-16.955-15.8-93.866-27.5-83.214zM367.49 60.46C350.68 55.583 290 64.488 290 64.488l13.257 55.596c47.285 42.861 78.786-53.417 64.233-59.624z"
        stroke="#F7F8F8"
        strokeWidth="20"
        strokeLinejoin="round"
      />
      <path
        d="M456.2 307.788c-15.937 37.208-36.745 74.306-64.31 102.355-43.422 44.186-118.4 61.102-187.635 49.203-67.611-11.62-139.384-53.505-158.006-105.12-17.833-49.43-41.837-98.574-34.868-154.716 6.532-52.627 69.288-151.001 140.633-174.535 22.123-7.298 64.133-19.234 104.978-12.658"
        stroke="#F7F8F8"
        strokeWidth="20"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

const CookieCrumbs2 = (props) => (
  <svg
    width={props.width || 478}
    height={props.height || 458}
    viewBox="0 0 478 458"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#F7F8F8">
        <path
          d="M467.669 270.481c-30.505 121.403-151.032 197.4-273.726 172.598C71.25 418.276-44.694 330.184 37.993 96.095"
          stroke="#F7F8F8"
          strokeWidth="20"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M464.943 272.577c-25.412 17.308-59.59 16.482-84.942-2.052-25.352-18.534-36.229-50.645-27.032-79.807"
          stroke="#F7F8F8"
          strokeWidth="20.103"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M347.99 196.433c-24.997 2.595-49.976-8.308-65.265-28.486-15.29-20.179-18.49-46.466-8.362-68.684"
          stroke="#F7F8F8"
          strokeWidth="19.137"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M118.179 179.04c22.35 1.1 39.787 19.753 39.382 42.127-.406 22.374-18.507 40.382-40.884 40.672-22.376.29-40.937-17.243-41.923-39.599-.985-22.356 15.962-41.454 38.277-43.134M298.181 269.953c24.532 1.209 43.67 21.681 43.225 46.24-.445 24.557-20.313 44.322-44.873 44.64-24.56.319-44.933-18.925-46.014-43.463-1.082-24.538 17.52-45.5 42.012-47.344M156.719 295.625c13.08.645 23.284 11.56 23.047 24.654-.238 13.094-10.83 23.633-23.926 23.802-13.095.17-23.957-10.09-24.534-23.174-.577-13.083 9.341-24.26 22.4-25.243"
          fillRule="nonzero"
        />
      </g>
      <path
        d="M163.728 10.753c-13.825 10.732-42.288 65.059-42.288 65.059l52.77 21.953c62.47-13.061 3.094-95.137-10.482-87.012zM393.867 86.526c-4.46 16.924 5.945 77.367 5.945 77.367l55.25-14.63c13.5-25.448-55.35-77.439-61.195-62.737z"
        stroke="#F7F8F8"
        strokeWidth="20"
        strokeLinejoin="round"
      />
      <g fill="#F7F8F8">
        <path
          d="M152.68 136.425c-19.304 15.029-45.173 19.225-68.395 11.093-23.222-8.13-40.497-27.434-45.674-51.035"
          stroke="#F7F8F8"
          strokeWidth="20.009"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M270.086 104.74c-7.38 24.023-27.175 42.756-51.723 48.945-24.549 6.19-49.997-1.136-66.491-19.14"
          stroke="#F7F8F8"
          strokeWidth="19.137"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M183.011 205.896c8.082-16.703 27.969-23.97 44.915-16.41 16.947 7.559 24.827 27.21 17.799 44.383-7.029 17.173-26.425 25.66-43.809 19.17-17.383-6.492-26.47-25.615-20.522-43.192M286.657 319.562c5.704-11.788 19.739-16.916 31.7-11.581 11.96 5.334 17.521 19.203 12.56 31.323-4.96 12.12-18.649 18.11-30.918 13.53-12.268-4.582-18.68-18.078-14.483-30.483"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

const CookieCrumbs3 = (props) => (
  <svg
    width={props.width || 367}
    height={props.height || 458}
    viewBox="0 0 367 458"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#F7F8F8">
        <path
          d="M356.669 270.481c-30.505 121.403-151.032 197.4-273.726 172.598"
          stroke="#F7F8F8"
          strokeWidth="20"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M352.617 273.267c-6.126 26.107-25.946 44.889-51.786 49.074-25.84 4.185-53.644-6.883-72.647-28.92"
          stroke="#F7F8F8"
          strokeWidth="19.137"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M172.77 373.993c13.08.644 23.284 11.56 23.047 24.653-.237 13.094-10.83 23.633-23.926 23.803-13.094.17-23.957-10.091-24.534-23.175-.576-13.083 9.341-24.26 22.4-25.242"
          fillRule="nonzero"
        />
      </g>
      <path
        d="M52.728 10.753C38.903 21.485 10.44 75.812 10.44 75.812l52.77 21.953c62.47-13.061 3.094-95.137-10.482-87.012zM129.333 226.102c-12.97-11.751-71.414-30.348-71.414-30.348l-12.456 55.78c23.71 59.252 94.229-13.473 83.87-25.432zM213.122 117.148c-4.46 16.924 5.945 77.367 5.945 77.367l55.25-14.63c13.5-25.449-55.35-77.439-61.195-62.737z"
        stroke="#F7F8F8"
        strokeWidth="20"
        strokeLinejoin="round"
      />
      <g fill="#F7F8F8" fillOpacity="0" stroke="#F7F8F8" strokeLinecap="round">
        <g fill="none">
          <path
            d="M103.698 320.307c18.153 16.402 26.777 41.15 22.801 65.43-3.976 24.282-19.986 44.646-42.33 53.842"
            strokeWidth="20.009"
          />
          <path
            d="M225.086 291.74c-7.38 24.023-27.175 42.756-51.723 48.945-24.549 6.19-49.997-1.136-66.491-19.14"
            strokeWidth="19.137"
          />
        </g>
      </g>
    </g>
  </svg>
);

const BoxCookie = styled(Box)`
  cursor: pointer;
  user-select: none;
  color: light.500;
`;

export function CookieBanner() {
  const [hideCookieBanner, setHideCookieBanner] = React.useState(false);
  const storedStep =
    typeof window !== `undefined`
      ? parseInt(window.localStorage.getItem("stepCookie") || 0)
      : 0;

  const [step, setStep] = React.useState(storedStep);
  const crunch = () => setStep(step + 1);

  React.useEffect(() => {
    window.localStorage.setItem("stepCookie", step);
    if (step > 3) {
      setHideCookieBanner(true);
    }
  }, [step]);

  if (hideCookieBanner) {
    return null;
  }

  return (
    <BoxCookie
      onClick={crunch}
      color="light.500"
      row
      fontSize="small"
      textAlign="center"
      alignItems="center"
      alignContent="baseline"
      justifyContent="center"
    >
      <Box>I {step < 1 ? "bake" : "eat"}</Box>
      <Box mt="xs" ml="xs">
        {step <= 0 && <CookieCrumbs width={16} height={16} />}
        {step === 1 && <CookieCrumbs1 width={16} height={16} />}
        {step === 2 && <CookieCrumbs2 width={16} height={16} />}
        {step === 3 && <CookieCrumbs3 width={16} height={16} />}
      </Box>
      <Box>, i don't store them in your browser.</Box>
    </BoxCookie>
  );
}
